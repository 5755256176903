<template>
    <sdPageHeader title="Transport Requests"> </sdPageHeader>
    <Main>
        <a-row :gutter="15">
            <a-col class="w-100" :md="24">
                <sdCards title="Filters ">
                    <a-form :model="filterForm" layout="vertical">
                        <a-row :gutter="25">
                            <a-col :md="6" :xs="6">
                                <a-form-item label="Supplier" name="partnerId">
                                    <a-select v-if="partnerApi.length" v-model:value="filterForm.partnerId"
                                        placeholder="Supplier" show-search style="width: 100%" :options="partnerData"
                                        :not-found-content="partnerFetching ? undefined : null
                                            " :filter-option="false" @search="(value) => searchPartner(value)">
                                        <template v-if="partnerFetching" #notFoundContent>
                                            <a-spin size="small" />
                                        </template>
                                    </a-select>
                                    <a-select v-else v-model:value="filterForm.partnerId" placeholder="Supplier">
                                    </a-select>
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item name="date" label="Date From">
                                    <a-date-picker :picker="'start'" v-model:value="filterForm.dateStart"
                                        :style="{ width: '100%' }" format="DD/MM/YYYY" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item name="date" label="Date To">
                                    <a-date-picker :picker="'start'" v-model:value="filterForm.dateEnd"
                                        :style="{ width: '100%' }" format="DD/MM/YYYY" />
                                </a-form-item>
                            </a-col>
                            <a-col :span="6">
                                <a-form-item label="Status" name="reportStatus">
                                    <a-select v-model:value="filterForm.reportStatus">
                                        <a-select-option :value="false">
                                            Declined </a-select-option>
                                        <a-select-option :value="true">
                                            Valid</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-col>

                            <a-col :span="6" class="button-container">
                                <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary"
                                    @click="onFilter()">
                                    <span>Filter</span>
                                </a-button>
                            </a-col>
                            <a-col :span="6" class="button-container">
                                <a-button class="ant-btn-lg" @click="clearFilters()" danger>
                                    <span>Clear Filters</span>
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                </sdCards>
            </a-col>
        </a-row>
        <a-row :gutter="15">
            <a-col class="w-100" :md="24">
                <sdCards title="Requests">
                    <div>
                        <TableWrapper class="table-responsive">
                            <a-table :columns="columns" :dataSource="transportRequestData" :loading="isLoading"
                                :pagination="pagination" @change="onHandleTableChange">
                                <template #file="{ record }">
                                    <a-tooltip>
                                        <template #title>Supplier Offre</template>
                                        <a-button v-if="record.file" class="edit" @click="downloadFile(record.file)">
                                            <sd-feather-icons type="file" size="14" />
                                        </a-button>
                                    </a-tooltip>
                                    &nbsp;&nbsp;&nbsp;
                                    <a-tooltip>
                                        <template #title>Valid Supplier Request</template>
                                        <a-button v-if="record.partnerOffreIsValid" class="edit"
                                            @click="downloadValidatedFile(record.id)">
                                            <sd-feather-icons type="file" size="14" />
                                        </a-button>
                                    </a-tooltip>
                                </template>
                                <template #details="{ record }">
                                    <a-button class="eye"
                                        @click="handleDetail(record, record.id, record.transportRequestId)">
                                        <sd-feather-icons type="eye" size="14" />
                                    </a-button>
                                </template>
                            </a-table>
                        </TableWrapper>
                    </div>
                </sdCards>
            </a-col>
        </a-row>
        <ClientOffre v-if="showClientOffreModal && selectedPartner.partnerId" :partner="selectedPartner"
            :partnerTransportRequestId="selectedPartnerTransportRequestId" :readOnly="true"
            @cancel="showClientOffreModal = false">
        </ClientOffre>
    </Main>
</template>
<script setup>
import { reactive, onMounted, ref, computed, defineAsyncComponent } from "vue";
import { Main, TableWrapper } from "../styled";
import { useStore } from "vuex";
import { useFileDownload } from '@/composable/useFileDownload';
import usePartnerSearch from '@/composable/usePartnerSearch';
import { DataService } from "@/config/dataService/dataService";
import moment from "moment";
const { state, dispatch } = useStore();
const { downloadFile, createAndTriggerDownloadLink } = useFileDownload();
import { notification } from "ant-design-vue";
//// Data
const transportRequestData = ref([]);
const isLoading = ref(false);

////// Filter
var filterForm = reactive({
    layout: "vertical",
    dateFrom: "",
    dateTo: "",
    partnerId: "",
    reportStatus: "",
});
/////
const columns = [
    {
        title: "Supplier",
        dataIndex: "partnerName",
        key: "partnerName",
    },
    {
        title: "Number",
        dataIndex: "number",
        key: "number",
    },
    {
        title: "Order Date",
        dataIndex: "orderDate",
        key: "orderDate",
    },
    {
        title: "Contact Person",
        dataIndex: "contactPerson",
        key: "contactPerson",
    },
    {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
    },
    {
        title: "Price",
        dataIndex: "price",
        key: "price",
    },
    {
        title: "Status",
        dataIndex: "offreIsValid",
        key: "offreIsValid",
    },
    { title: 'Files', dataIndex: 'file', key: 'file', slots: { customRender: 'file' } },
    { title: 'Details', dataIndex: 'details', key: 'details', slots: { customRender: 'details' } },
];

// pagination logic 

const pagination = ref({
    showSizeChanger: true, 
    current: 1,
    pageSize: 10,
    orderBy: "partnerOffreDate",
    columnKey: "partnerOffreDate",
    orderByDirection: "desc",
    total: 0,
});


/// Api call 
const getAllData = async () => {
    try {
        isLoading.value = true;
        const query = await DataService.get(
            `api/TransportRequest/TransportRequestPartner?addFiles=true&PageSize=${pagination.value.pageSize}&PageNumber=${pagination.value.current}&dateFrom=${filterForm.dateFrom}&dateTo=${filterForm.dateTo}&partnerId=${filterForm.partnerId}&IsValid=${filterForm.reportStatus}&OrderBy="${pagination.value.columnKey}"&OrderByDirection=${pagination.value.order}`
        );
        transportRequestData.value = query.data.map(item => ({
            ...item,
            key: item.id,
            partnerName: item.partnerName,
            number: item.number,
            orderDate: moment(item.orderDate).format('YYYY/MM/DD'),
            contactPerson: item.contactPersonName,
            offreIsValid: item.partnerOffreIsValid ? "Valid" : "Declined",
            price: item.price ? `${item.price} ${item.currencyName}` : "",
            quantity: item.quantity ? `${item.quantity} ${item.unitOfMeasureName}` : "",
        }));

        pagination.value.total = query.recordsFiltered ?? query.recordsTotal;
        isLoading.value = false;
    } catch (err) {
        console.error(err);
        isLoading.value = false;
    }
};

/// Details logixc
var selectedPartner = ref(null);
var selectedPartnerTransportRequestId = ref(null);
var selectedTransportRequestId = ref(null);
const ClientOffre = defineAsyncComponent(() => import("@/common/components/ClientOffre.vue"));
var showClientOffreModal = ref(false);
const handleDetail = (partner, partnerTransportRequestId, transportId) => {
    selectedPartner.value = partner;
    selectedPartnerTransportRequestId.value = partnerTransportRequestId;
    selectedTransportRequestId.value = transportId;
    showClientOffreModal.value = true;
}
/// Partner logic
const partnerApi = computed(() => state.partnerApi.data);

const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
    filterForm.partnerId
);
// Filter logic 
const onFilter = () => {

    pagination.value.columnKey= "partnerOffreDate";
    pagination.value.order= "desc";
    pagination.value.current = 1;
    pagination.value.orderBy = pagination.value.columnKey ?? "partnerOffreDate";
    pagination.value.orderByDirection = pagination.value.order ?? "descend";
    pagination.value.pageSize = 10;
    filterForm.dateFrom = filterForm.dateStart ? moment(filterForm.dateStart).format('YYYY/MM/DD') : "";
    filterForm.dateTo = filterForm.dateEnd ? moment(filterForm.dateEnd).format('YYYY/MM/DD') : "";
    getAllData();

}
const clearFilters = () => {
    filterForm.dateStart = null;
    filterForm.dateEnd = null;
    filterForm.dateFrom = null;
    filterForm.dateTo = null;
    filterForm.partnerId = "";
    filterForm.reportStatus = "";
    onFilter();
}

const onHandleTableChange = (paginationTable) => {
    pagination.value.current = paginationTable.current;
    pagination.value.pageSize = paginationTable.pageSize;
    getAllData();
};

const downloadValidatedFile = async (inspectionRequestId) => {
    try {
        var response = await DataService.getFile(
            `api/TransportRequest/GetValidTransportRequestPartnerPdf?InspectionRequestPartnerId=${inspectionRequestId}`
        );
        var byteCharacters = response;
        var blob = new Blob([byteCharacters], { type: "application/pdf" });
        createAndTriggerDownloadLink(blob)
    } catch (err) {
        notification.error({
            message: "Error while downloading PDF",
        });
    }
}
/// Cycle 
onMounted(() => {
    getAllData();
    dispatch("partnerInfo");

})
</script>